import { Controller } from '@hotwired/stimulus'

const NOT_PROVIDED = "Not Provided"

export default class extends Controller {
  static targets = [ "submit", "input" ]

  connect() {
  }

  addConfirmMessageToSubmit(event) {
    const select = event.currentTarget
    const country = event.currentTarget.dataset.country

    if (select.value != country) {
      this.submitTarget.dataset.confirm = select.dataset.text
    } else {
      this.submitTarget.removeAttribute('data-confirm')
    }
  }

  submitFormOnChange(event) {
    const select = event.currentTarget

    select.form.submit()
  }

  // example usage:  
  // <div data-controller="forms--select">
  //  <input type="text" data-forms--select-target="input" />
  //  <a href="#" class="not-provided" data-action="click->forms--select#addNotProvided">Set to "Not Provided"</a>
  // </div>
  addNotProvided() {
    this.inputTarget.value = NOT_PROVIDED
  }
}
